import { getSettings } from './settingsUtil'
import { getAppId, getGen, isTWA } from './twa'
import { CHAT_ALL } from './chat/chatUtil'
import { getState } from './StoreManager'
import { imFemale, imModel } from './my'
import { AND_UD_APP_ID, IOS_DATING_APP_ID, isIOSPWA, MYCHARMAI_APP_ID } from './api/PWAUtil'
import { checkCountry } from './ageRating/ageRatingUtil'
import { getUsdBalance, isStreamButtonAvailable } from './stream/streamUtil'

const defaultConfigTWA = {
  privates_disabled: false,
  chat_mode: CHAT_ALL,
  custom_filters: false,
  payout_disabled: false,
  diversity: false
}

const defaultConfigWEB = {
  privates_disabled: false,
  chat_mode: CHAT_ALL,
  custom_filters: false,
  payout_disabled: false,
  diversity: false
}

export function getAppConfigParam(param) {
  return getAppConfig()[param]
}
export function getAppConfig() {
  return getSettings('mode_' + getAppId() + '_' + getGen()) || (isTWA() ? defaultConfigTWA : defaultConfigWEB)
}

export function getAppTagsIdExcluded() {
  return getAppConfigParam('tagsIdExcluded') || []
  // return (getState('intl.appSourceConfig') || {})['tagsIdExcluded'] || []
}

export function is12Config() {
  // return true
  const config = getAppConfig()
  return config.r
}

export function isNoCyberApp() {
  // return true
  const config = getAppConfig()
  return config.ai_disabled
}

export function isNoDatingInConfig() {
  // return true
  const config = getAppConfig()
  return config.no_dating
}

export function isCallDisableInConfig() {
  // return true
  const config = getAppConfig()
  return config.privates_disabled
}

export function isKickMode() {
  const config = getAppConfig()
  return config.privates_kick
}

export function isDatingFilterDisable() {
  const config = getAppConfig()
  return config.custom_filters
}

export function isPayoutDisable(onlyConfig) {
  const config = getAppConfig()
  const dollars = getState('auth.usdBalance')
  if (config.payout_disabled) {
    if (dollars && imModel()) {
      return false
    }
    return config.payout_disabled
  }

  //для IOS вывод скрыт всегда, не смотря на пол
  if (isTWA() && !dollars) {
    return true
  }

  //если я мужик без долларов то кнопки тоже нет
  if (!imFemale() && !dollars && !onlyConfig) {
    return true
  }

  return false
}

export function isDiversity() {
  const config = getAppConfig()
  return config.diversity
}

export function isDatingApp() {
  if (checkCountry('ru') && !getUsdBalance() && !getState('auth.purchasedCoins', 0)) {
    return true
  }
  return getAppConfigParam('datingApp')//getState('datingApp')
  // const appId = getAppId()
  // return appId === IOS_DATING_APP_ID || appId === AND_UD_APP_ID  || isAIDatingApp()
  // return isAIDatingApp()
}

export function isAIDatingApp() {
  // return true
  if (isDatingApp()) {
    return false
  }

  const appId = getAppId()
  return appId === MYCHARMAI_APP_ID || appId === IOS_DATING_APP_ID || appId === AND_UD_APP_ID || getAppConfigParam('aiDolls') || getState('aiDolls') //getState('aiDolls')
}
